/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { Fragment } from 'react';
import { MDXProvider } from "@mdx-js/react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Seo from '../components/seo'
import Hero from '../components/hero'
import { Link } from "gatsby"
import Header from "./header"
import Footer from "./footer"
import Menu from './menu/menu'
import LeadGenCallout from './callouts/lead-gen-callout';

import "./layout.css"

const shortcodes = { Seo, Link, Hero, GatsbyImage, LeadGenCallout }

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div id="outer-container" style={{ height: '100%' }}>

      <Menu />

      <div id="page-wrap" style={{ height: '100%', overflow: 'auto' }}>
        <Header siteTitle={data.site.siteMetadata?.title || `Title`} />

        <div style={{ backgroundColor: '#f9f9f9' }}>

          <main>{children}</main>

        </div>
        <Footer />
      </div>

    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ({ children }) => {
  return (
    <MDXProvider components={shortcodes}><Layout>{children}</Layout></MDXProvider>
  )
}

