import React, { useState } from 'react';
import Menu from 'react-burger-menu/lib/menus/slide'

import { Link } from 'gatsby'

import * as style from './menu.module.css'

var styles = {
  bmBurgerButton: {
    position: 'absolute',
    width: '36px',
    height: '30px',
    right: '18px',
    top: '15px '
  },
  bmBurgerBars: {
    background: 'rgba(249,249,249, 0.4)'
  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: '#bdc3c7'
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    top: 0
  },
  bmMenu: {
    background: '#373a47',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em'
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em'
  },
  bmItem: {
    display: 'block',
    marginBottom: '1rem',
    color: 'rgba(249,249,249, 0.6)',
    fontSize: '1.5rem',
    fontVariationSettings: '"wght" 570',
    textDecoration: 'none'

  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)'
  }
}



const Hamburger = () => {

  const [menuState, setMenuOpen] = useState({ menuOpen: false });

  const closeMenu = () => {
    setMenuOpen({ menuOpen: false })
  }

  const openMenu = () => {
    setMenuOpen({ menuOpen: true })
  }


  return (
    <Menu
      styles={styles}
      pageWrapId={"page-wrap"}
      outerContainerId={"outer-container"}
      onOpen={openMenu}
      onClose={closeMenu}
      isOpen={menuState.menuOpen}
      right>
      <Link to="/" onClick={() => closeMenu()}>Home</Link>
      <Link to="/solutions/" onClick={() => closeMenu()}>Solutions</Link>
      <Link to="/team/" onClick={() => closeMenu()}>Team</Link>
      <Link to="/blog/" onClick={() => closeMenu()}>Blog</Link>
      <Link to="/contact/" onClick={() => closeMenu()}>Contact</Link>
    </Menu>
  );

}

export default Hamburger