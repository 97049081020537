import * as React from "react"
import * as style from './socialicons.module.css'

const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={style.icon}{...props}>
    <g data-name="icon 2">
      <circle cx={12} cy={12} r={12} fill="currentColor" />
      <path fill="#343434"
        d="M20 12a1.8 1.8 0 0 0-1.8-1.8 1.8 1.8 0 0 0-1.2.5 8.5 8.5 0 0 0-4.6-1.4l.7-3.8 2.7.6a1.3 1.3 0 1 0 0-.7L13 4.8a.3.3 0 0 0-.2 0 .3.3 0 0 0-.2.2l-.9 4.2A8.6 8.6 0 0 0 7 10.7 1.8 1.8 0 0 0 4 12a1.8 1.8 0 0 0 1 1.6 3 3 0 0 0 0 .5c0 2.7 3.1 4.9 7 4.9s7-2.2 7-4.9a3 3 0 0 0 0-.5 1.7 1.7 0 0 0 1-1.6ZM8 13.3a1.3 1.3 0 1 1 1.3 1.2A1.3 1.3 0 0 1 8 13.2Zm7 3.2a4.6 4.6 0 0 1-3 1 4.6 4.6 0 0 1-3-1 .3.3 0 0 1 0-.4.3.3 0 0 1 .5 0 3.9 3.9 0 0 0 2.5.7 4 4 0 0 0 2.5-.7.3.3 0 0 1 .4 0 .3.3 0 0 1 0 .4Zm-.2-2a1.3 1.3 0 1 1 1.2-1.3 1.3 1.3 0 0 1-1.3 1.3Z" />
    </g>
  </svg>
)

export default SvgComponent