import * as React from 'react'

import * as layoutStyles from './layout.module.css'
import * as heroStyles from './hero.module.css'

import geoLines from '../images/waves.svg'

import { Link } from "gatsby"
import PlayButton from './icons/playbutton'

const Hero = (props) => {
  const { title, intro, links, children } = props

  return (
    <div className={heroStyles.hero}>
      <div className={layoutStyles.container}>
        <h1>{title}</h1>
        <p className={heroStyles.intro}>{intro}</p>

        {links && links.map(({ link, name, icon }, index) => {

          const linkElement =
            <Link
              key={index}
              to={link}>
              {icon && icon === 'play' && <PlayButton />}<span style={{ verticalAlign: 'middle', marginLeft: '0.25rem' }}>{name}</span></Link>

          return links.length > 1 ?
            index === 0 ? (
              linkElement // first link
            ) :
              (linkElement) // second link
            : (linkElement) // only link

        })}

        {children}

      </div>
    </div >
  )
}

export default Hero