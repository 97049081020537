import * as React from "react"
import * as headerStyles from './header.module.css'
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Menu from './menu/menu'

import Logo from './logo'

const Header = ({ siteTitle }) => (
  <header>
    <div className={headerStyles.container}>
      <div className={headerStyles.logoContainer}>
        <div className={headerStyles.logo}>
          <Link to="/" >
            <Logo />
          </Link>
        </div>
        <Link to="/">
          <span className={headerStyles.title}>
            GL Digital®
          </span>
        </Link>
      </div>
    </div>
  </header >
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
