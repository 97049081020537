import * as React from 'react'

import * as iconStyle from './icon.module.css'

const PlayButton = (props) => (
  <svg width="18px" height="18px" xmlns="http://www.w3.org/2000/svg" {...props} className={iconStyle.icon}>
    <title>Play Button</title>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-70-477h400v1946H-70z" />
      <g transform="translate(1 1)">
        <path
          d="M6.564 5.107c-.159-.143-.564-.143-.564 0v5.786c0 .143.406.143.566 0l5.313-2.636a.338.338 0 0 0 .003-.514L6.564 5.107Z"
          fill="#F9F9F9"
          fillRule="nonzero"
        />
        <circle stroke="#F9F9F9" strokeWidth={2} cx={8} cy={8} r={8} />
      </g>
    </g>
  </svg>

)


export default PlayButton