/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it


import * as React from "react"
import Layout from "./src/components/layout"

// export function shouldUpdateScroll(prevRouterProps, { location }) {
//   window.scrollTo(0, 0)
//   const body = document.getElementsByTagName('body')[0]
//   body.scrollTop = 0
//   return false
// }

// const scrollTo = (id) => () => {
//   const el = document.querySelector(id)
//   if (el) return window.scrollTo(0, el.offsetTop - 20)
//   return false
// }

// export const onRouteUpdate = ({ location: { hash } }) => {
//   console.log(location)
//   if (hash) {
//     window.setTimeout(scrollTo(hash), 10)
//   }
// }



export function wrapPageElement({ element, props }) {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>
}
