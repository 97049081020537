
import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import * as styles from './lead-gen-callout.module.css'


const LeadGenCallout = ({ tagline = 'Marketing' }) => {

    return (
        <div className={styles.callout}>
            <div className={styles.calloutLeft}>
                <StaticImage src="../../images/Gary Lewis Cheetham Founder of GL Digital Standing Outside the Harley-Davidson Dealership Mike Bruno's Freedom Harley-Davidson in Baton Rouge, Louisiana taken on December 2022.jpg" placeholder="blurred"
                    alt="GL Digital founder Gary Lewis Cheetham standing outside the Harley Davidson Dealership Mike Bruno's Freedom Harley Davidson Baton Rouge Louisiana"
                    layout="fixed"
                    className={styles.image}
                    width={400}
                    height={785}
                    transformOptions={{ fit: 'cover' }}
                />
            </div>
            <div className={styles.calloutLeftMobile}>
                <p className={styles.calloutHeadingMobile}>I Can <strong className={styles.highlight}>Run The Marketing</strong> At Your Dealership</p>

                <StaticImage src="../../images/GL Digital founder Gary Lewis Cheetham standing outside the Harley Davidson Dealership Mike Bruno's Freedom Harley Davidson Baton Rouge Louisiana.jpg" placeholder="blurred"
                    alt="GL Digital founder Gary Lewis Cheetham standing outside the Harley Davidson Dealership Mike Bruno's Freedom Harley Davidson Baton Rouge Louisiana"
                    className={styles.image}
                    height={440}
                    objectPosition="50% 10%"
                />
            </div>

            <div className={styles.calloutRight}>

                <p className={styles.calloutHeading}>I Can <strong className={styles.highlight}>Run The {tagline}</strong> At Your Dealership</p>

                <div>
                    <p>Hi, I'm Gary. I've been working in auto businesses since I was a teenager at my dad's dealership.</p>

                    <p>My clients get these benefits:</p>

                    <p><strong>Completely done-for-you dealership marketing</strong> - across Facebook, Google and TikTok.</p>
                    <p><strong>Pre-qualified sales leads</strong> - Good credit local customers, attached to a vehicle so easy to close.</p>

                    <p>Fixed monthly price, no contract. Cancel anytime (but you won't want to)</p>
                </div>

                <Link to="/contact/sales" className={styles.callButton}>Contact Me</Link>
            </div>
        </div>
    )

}

export default LeadGenCallout