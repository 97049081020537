import * as React from 'react'
import * as footerStyles from './footer.module.css'
import { Link } from "gatsby"

import { StaticImage } from "gatsby-plugin-image"

import Logo from './logo'
import Socials from './footer/socials'
import FooterLinks from './footer/footer-links'

const Footer = ({ }) => (
  <footer class={footerStyles.footer}>
    <div class={footerStyles.container}>
      <div class={footerStyles.row}>
        <span class={footerStyles.logobox}>
          <div class={footerStyles.logo}>
            <Logo />
          </div>
          <span>
            GL Digital Ltd
            © {new Date().getFullYear()}
          </span>
        </span>
        <p style={{ marginTop: '1rem' }}>At GL Digital our mission is to put power back in the hands of local dealerships. We are specialists in direct-to-consumer marketing solutions for the automotive industry.</p>
      </div>

      <div class={footerStyles.row}>
        <div class={footerStyles.column}>

          <FooterLinks>
            <Link to="/">Home</Link>
            <Link to="/blog/">Blog</Link>
            <Link to="/contact/">Contact</Link>
            <Link to="/terms-and-conditions/">Terms & Conditions</Link>
            <Link to="/privacy-policy/">Privacy Policy</Link>
          </FooterLinks>
        </div>
      </div>
      <div class={footerStyles.row}>
        <div class={footerStyles.column}>
          <div className={footerStyles.socials}>
            <Socials />
          </div>
          <div style={{ margin: '0 1rem' }}>
            <p>61 Mosley Street, Manchester, M23HZ</p>

            <div style={{ display: 'flex' }}>
              <a href="https://www.manchesterdigital.com/" rel="nofollow noreferrer noopener">
                <StaticImage src="../images/manchester-digital.png" height={60} placeholder="tracedSVG" />
              </a>
              <a href="https://www.gmchamber.co.uk/" rel="nofollow noreferrer noopener">
                <StaticImage src="../images/chamber.png" height={60} placeholder="tracedSVG" />
              </a>

            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer