import * as React from 'react'

import Facebook from '../icons/facebook'
import Instagram from '../icons/instagram'
import TikTok from '../icons/tiktok'
import YouTube from '../icons/youtube'
import LinkedIn from '../icons/linkedin'
import Twitter from '../icons/twitter'
import Reddit from '../icons/reddit'

import * as socialStyles from './socials.module.css'
import { Link } from 'gatsby'

const Socials = () => (
  <div className={socialStyles.socials}>
    <div className={socialStyles.inner}>
      <a class="social-item social-item-facebook" href="https://www.facebook.com/gldigitaluk" target="_blank"
        rel="noopener noreferrer nofollow" aria-label="Facebook">
        <Facebook />
      </a>

      <a class="social-item social-item-instagram" href="https://www.instagram.com/gldigitaluk/"
        target="_blank" rel="noopener noreferrer nofollow" aria-label="Instagram">
        <Instagram />
      </a>

      <a class="social-item social-item-tiktok" href="https://www.tiktok.com/@gldigital" target="_blank"
        rel="noopener noreferrer nofollow" aria-label="TikTok">
        <TikTok />
      </a>

      <a class="social-item social-item-youtube"
        href="https://www.youtube.com/channel/UCoGZHkcb0R2l-KYrOqcw1nw" target="_blank"
        rel="noopener noreferrer nofollow" aria-label="YouTube">
        <YouTube />
      </a>

      <a class="social-item social-item-linkedin"
        href="https://www.linkedin.com/company/gl-digital-ltd/" target="_blank"
        rel="noopener noreferrer nofollow" aria-label="Linkedin">
        <LinkedIn />
      </a>

      <a class="social-item social-item-twitter" href="https://www.twitter.com/gldigitaluk" target="_blank"
        rel="noopener noreferrer nofollow" aria-label="Twitter">
        <Twitter />
      </a>

      <a class="social-item social-item-reddit" href="https://www.reddit.com/r/gldigital/" target="_blank"
        rel="noopener noreferrer nofollow" aria-label="Reddit">
        <Reddit />
      </a>


    </div>

  </div>
)

export default Socials