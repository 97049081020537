import * as React from "react"
import * as style from './socialicons.module.css'

const SvgComponent = (props) => (
  <svg className={style.icon} viewBox="0 0 160 100" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M154 17.5c-1.82-6.73-7.07-12-13.8-13.8-9.04-3.49-96.6-5.2-122 .1-6.73 1.82-12 7.07-13.8 13.8C.32 35.5.01 74.2 4.5 92.5c1.82 6.73 7.07 12 13.8 13.8 17.9 4.12 103 4.7 122 0 6.73-1.82 12-7.07 13.8-13.8 4.35-19.5 4.66-55.8-.1-75z"
      fill="currentColor" />
    <path d="M105 55 64.2 31.6v46.8z" fill="#343434" />
  </svg>
)

export default SvgComponent