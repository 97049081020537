import * as React from 'react'
import { Link } from "gatsby"


import * as styles from './footer-links.module.css'

const FooterLinks = ({ title = "Site Links", children }) => {
  return (
    <div className={styles.wrap}>
      <h4 className={styles.header}>{title}</h4>
      <ul className={styles.list}>
        {children.map((node, index) => <li key={index}>{node}</li>)}

      </ul>
    </div>
  )
}

export default FooterLinks